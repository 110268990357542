main .welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: calc(var(--default-space) * 8);
  padding-bottom: calc(var(--default-space) * 6);
}

main .welcome-container .text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  max-width: 50%;
}

main h1.text-wrapper {
  font-size: var(--h1-title-font-size);
  font-weight: var(--h1-title-font-weight);
  line-height: normal;

  color: var(--black,);
  text-align: center;

  margin: 1rem;
}

.container .course-filter-container {
  margin: calc(var(--default-space) * 4);
}

.container .course-filter-container-with-suggestion {
  margin: 1rem;
  display: flex;
  align-items: center;
}

.container .course-filter-container-with-suggestion .suggestion-container-text {
  font-size: var(--input-titles-font-size);
  font-style: var(--input-titles-font-style);
  font-weight: var(--input-titles-font-weight);
  letter-spacing: var(--input-titles-letter-spacing);
  line-height: var(--input-titles-line-height);
}

.scroll-tags-container {
  display: flex;
  align-items: center;
}

.all-courses-container .controls-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: calc(var(--default-space) * 2) 0;
}

.all-courses-container .controls-container button {
  border: 1px solid var(--lines);
  border-radius: 8px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px 16px;

  color: var(--black);
  font-size: 14px;
  background-color: #fff;
}

.all-courses-container .controls-container button svg {
  margin-right: var(--default-space);
}

.action-button-container {
  margin: 0 auto;
}

.banner-container {
  background-color: var(--secondary);
  border: 1px solid var(--lines);
  border-radius: 30px;
  padding: 15px;
}

@media (min-width: 992px) {
  .banner-container {
    padding: 70px;
  }
}

.banner-container .banner-text-container h4 {
  color: var(--violet);
  font-size: var(--h1-title-font-size);
  font-style: var(--h1-title-font-style);
  font-weight: var(--h1-title-font-weight);
  letter-spacing: var(--h1-title-letter-spacing);
  line-height: var(--h1-title-line-height);
}

.banner-container .banner-text-container p {
  color: var(--white);
  font-size: var(--paragraph-font-size);
  font-style: var(--paragraph-font-style);
  font-weight: var(--paragraph-font-weight);
  letter-spacing: var(--paragraph-letter-spacing);
  line-height: var(--paragraph-line-height);
}

.avatar {
  background-position: 50% 50%;
  background-size: cover;
  border: 1px solid;
  border-color: var(--lines);
  border-radius: 200px;
  height: 40px;
  margin-bottom: -2px;
  margin-top: -2px;
  position: relative;
  width: 80px;
  height: 80px;
}
