.range-slider-container {
  @apply p-[calc(var(--default-space)*2)] relative;
}

.slider {
  @apply relative w-full;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  @apply absolute;
}

.slider__track,
.slider__range {
  @apply h-0.5 rounded;
}

.slider__track {
  @apply bg-slate-200 w-full z-[1];
}

.slider__range {
  @apply bg-mydra-black z-[2];
}

.slider__left-value,
.slider__right-value {
  @apply text-[--text] text-sm mt-5;
}

.slider__left-value {
  @apply left-0 -translate-x-1/2;
}

.slider__right-value {
  @apply right-0 translate-x-1/2;
}

.thumb,
.thumb::-webkit-slider-thumb {
  @apply appearance-none;
  -webkit-tap-highlight-color: transparent;
  background: transparent;
}

input[type='range']::-moz-range-progress {
  @apply bg-transparent -z-0;
}

.thumb {
  @apply absolute w-full h-0 outline-none pointer-events-none;
}

.thumb--left {
  @apply z-[5] left-0;
}

.thumb--right {
  @apply z-[4] right-0;
}

.thumb::-moz-range-thumb {
  @apply !bg-white !border !border-solid !border-[var(--lines)] rounded-full shadow-sm cursor-pointer h-6 w-6 mt-1 pointer-events-auto relative;
}

.thumb::-webkit-slider-thumb {
  @apply !bg-white !border !border-solid !border-[var(--lines)] rounded-full shadow-sm cursor-pointer h-6 w-6 mt-1 pointer-events-auto relative;
}

.big .thumb::-moz-range-thumb {
  @apply w-8 h-8;
}

.big .thumb::-webkit-slider-thumb {
  @apply w-8 h-8;
}

.thumb::-moz-range-thumb:hover {
  @apply !border-mydra-black;
}

.thumb::-webkit-slider-thumb:hover {
  @apply !border-mydra-black;
}

.thumb::-webkit-slider-thumb:active {
  @apply bg-mydra-black;
}

.thumb::-webkit-slider-thumb:hover,
.thumb::-moz-range-thumb:hover {
  @apply border-mydra-black;
}

.thumb::-webkit-slider-thumb:active,
.thumb::-moz-range-thumb:active {
  @apply bg-mydra-black;
}
