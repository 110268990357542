@media (min-width: 980px) {
  .course-detail-header-background {
    background:
      linear-gradient(0deg, #c09ef8 0%, #c09ef8 100%),
      url('../../public/images/course-bg-image.png'),
      lightgray 50% / cover no-repeat;
    background-blend-mode: soft-light, normal;
    background-position: bottom;
  }
}

.mobile-detail-header-background {
  background:
    linear-gradient(0deg, #c09ef8 0%, #c09ef8 100%),
    url('../../public/images/course-bg-image.png'),
    lightgray 50% / cover no-repeat;
  background-blend-mode: soft-light, normal;
  background-position: bottom;
}

.course-info-container {
  padding-right: calc(var(--default-space) * 2);
}

.text-container {
  padding: calc(var(--default-space) * 2) 0;
}

.course-info-container h4 {
  padding-left: calc(var(--default-space) * 2);
}

.course-info-container p {
  color: var(--black, #292626);
  padding-top: calc(var(--default-space) * 2);
}

.course-content-container {
  width: 100%;
  margin: 2rem 0;
  padding-right: calc(var(--default-space) * 6);
}

@media (min-width: 992px) {
  .course-content-container {
    margin: 15rem 0;
  }
}

.course-content-content {
  margin: calc(var(--default-space) * 6) 0;
}

.instructor-info-container {
  transform: translateY(150px);
}

.instructor-container h2 {
  text-align: center;
  margin: calc(var(--default-space) * 2) 0;
}

.course-detail-mobile {
  transform: translateY(16px);
}

.hide-mobile {
  display: none;
}

@media (min-width: 768px) {
  .show-mobile {
    display: block;
  }
}

@media (min-width: 992px) {
  .show-mobile {
    display: none;
  }

  .hide-mobile {
    display: block;
  }
}

.banner-input-container input {
  height: 66%;
  margin-right: 1rem;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
}

.suggestion-success-state {
  background: var(--success-background);
  border: 1px solid var(--success);
  border-radius: var(--sm-border-radius);
  padding: var(--default-space);
}

.suggestion-success-state span {
  padding-left: calc(var(--default-space) / 2);
}

.load-more-button {
  padding: calc(var(--default-space) * 6) 0;
}

.trending-carousel-container {
  margin-bottom: calc(var(--default-space) * 8);
}

.loading-container {
  background-color: var(--light-purple);
}

.loading-container {
  width: 255px;
  height: 20px;
  padding-bottom: var(--default-space);
}

@media (min-width: 992px) {
  .loading-container {
    width: 455px;
  }
}
